<template>
  <v-card
    outlined
    class="mt-1"
  >
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <DatePicker
            :value="delayUntil"
            @input="$emit('update:delayUntil', $event)"
            :label="$t('advert.delay_until')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="delayComment"
            @input="$emit('update:delayComment', $event)"
            outlined
            :label="$t('advert.delay_comment')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from '@/components/views/admin/common/DatePicker'

export default {
  components: { DatePicker },
  name: 'DelayUntil',
  props: {
    delayUntil: Number,
    delayComment: String
  }
}
</script>

<style scoped>

</style>
